import req from "../../services/api/request";

class QuestionsApi {
  getQuestions({page, topic_id, find}) {
    return req.get(`/api/valid/questions_by_topic?find=${find}&topic_id=${topic_id}&count=10&page=${page}&dir=desc&prop=createdOn`);
  }
  getQuestion(id) {
    return req.get(`/api/valid/questions/${id}`);
  }
  removeQuestion(id) {
    return req.delete("/api/valid/questions/" + id);
  }
  updateQuestion(id, params) {
    return req.post("/api/valid/questions", id, params);
  }
  close(id){
    return req.get(`/api/valid/archive?id=${id}`);
  }
  defineReplyThread(id){
    return req.get(`/api/valid/defineAnswer?answer_id=${id}`);
  }
  deleteSubReply(id){
    return req.delete(`/api/valid/comment/${id}`);
  }
  deleteReplyThread(id){
    return req.delete(`/api/valid/answer/${id}`);
  }

  // TODO
  updateSubReply(id, data){
    return req.post(`/archive/${id}`, data);
  }
  updateReplyThread(id, data){
    return req.post(`/archive/${id}`, data);
  }
}

export default new QuestionsApi();
