import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { Button, IconButton, Pagination } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import EditIcon from "@mui/icons-material/Edit";
import { Questions } from "../../store/Questions";
import { Topics } from "../../store/Topics";
import { observer } from "mobx-react-lite";
import { Dialog } from "../common/dialog/index";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Box from "@mui/material/Box";

const QuestionsView = observer(() => {
  useEffect(() => {
    if (Topics.list[0]) {
      Questions.updateSearchTopic(Topics.list[0].id);
    }
  }, [Topics.list]);

  return (
    <div className="content">
      <div className="questions">
        <div className="search">
          <div className="title">Вопросы</div>
          <div className="search-panel">
            <div className="search-field" style={{ flex: 2 }}>
              <IconButton className="search-icon" onClick={() => {}}>
                <SearchSharpIcon />
              </IconButton>
              <InputBase
                id="outlined-basic"
                placeholder="Поиск..."
                value={Questions.searchQuestion}
                onChange={(e) => {
                  Questions.updateSearchQuestion(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    Questions.search();
                  }
                }}
              />
              <Button
                onClick={(e) => {
                  Questions.search();
                }}
                variant="contained"
                style={{ background: "#002984", borderRadius: "0 5px 5px 0" }}
              >
                Найти
              </Button>
            </div>
            <FormControl style={{ flex: 1 }} size={"small"} fullWidth>
              <InputLabel id="topic">Категория</InputLabel>
              <Select
                labelId="topic"
                id="topic-select"
                value={Questions.searchTopic}
                label="Категория"
                onChange={(e) => {
                  Questions.updateSearchTopic(e.target.value);
                }}
              >
                {Topics.list.map(({ id, content }) => (
                  <MenuItem key={id} value={id}>
                    {content}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="list">
          {Questions.list.map(({ id, header, user_name, topic_id }) => {
            return (
              <div key={id} className="item">
                <div className="item-content">
                  <Box sx={{ p: 1 }}>
                    <QuestionMarkIcon />
                  </Box>
                  <div className="item-info">
                    <Link to={`/question/view/${id}`}>
                      <div className="item-title">{header}</div>
                    </Link>
                    <div className="item-bottom">
                      <div className="item-topic">
                        {Topics.loaded && Topics.getById(topic_id)
                          ? Topics.getById(topic_id).content
                          : ""}
                      </div>
                      <div className="item-author">{user_name}</div>
                    </div>
                  </div>
                </div>
                <div className="item-btn">
                  <Link to={`/question/view/${id}`}>
                    <IconButton>
                      <VisibilityIcon style={{ color: "black" }} />
                    </IconButton>
                  </Link>
                  {/*<Link to={`/question/edit/${id}`}>
                  <IconButton>
                    <EditIcon style={{ color: "black" }} />
                  </IconButton>
                </Link>*/}
                  <Dialog
                    title={"Удалить вопрос?"}
                    onSubmit={() => Questions.delete(id)}
                  ></Dialog>
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <Pagination
            page={Questions.page + 1}
            count={Questions.totalPages}
            onChange={(e, newPage) => {
              Questions.setPage(newPage);
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default QuestionsView;
