import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Tabs, Tab } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Reports } from "./../../store/Reports";
import ReportIcon from "@mui/icons-material/Report";
import Box from '@mui/material/Box';

const ReportsPage = observer((props) => {
  const [tab, settab] = useState("questions");

  return (
    <div className="content">
      <div className="reports">
        <div className="search">
          <div className="title">Жалобы</div>
          {/*<div className="search-panel">
            <div className="search-field" style={{ flex: 2 }}>
              <IconButton className="search-icon" onClick={() => {}}>
                <SearchSharpIcon />
              </IconButton>
              <InputBase
                id="outlined-basic"
                placeholder="Поиск..."
                value={Reports.searchStr}
                onChange={(e) => {
                  Reports.updateSearchStr(e.target.value);
                }}
                onKeyPress={(e) => {
                  Reports.updateSearchStr(e.target.value);
                }}
              />
              <Button
                onChange={(e) => {
                  Reports.search();
                }}
                variant="contained"
                style={{ background: "#002984", borderRadius: "0 5px 5px 0" }}
              >
                Найти
              </Button>
            </div>
            <FormControl style={{ flex: 1 }} size={"small"} fullWidth>
              <InputLabel id="topic">Категория</InputLabel>
              <Select
                labelId="topic"
                id="topic-select"
                value={Reports.searchTopic}
                label="Категория"
                onChange={(e) => {
                  Reports.updateSearchTopic(e.target.value);
                }}
              >
                {Topics.list.map(({ id, content }) => (
                  <MenuItem key={id} value={id}>
                    {content}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>*/}
        </div>
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            settab(newValue);
          }}
        >
          <Tab value="questions" label="Вопросы" />
          <Tab value="answers" label="Ответы" />
          <Tab value="comments" label="Комментарии" />
          <Tab value="users" label="Пользователи" />
        </Tabs>
        <div className="list">
          {Reports.list[tab].map((i) => {
            return (
              <div key={i.id} className="item">
                <div className="item-content">
                  <Box sx={{ p: 1 }}>
                    <ReportIcon />
                  </Box>
                  <div className="item-info">
                    <div className="item-title">
                      {tab !== "users" ? i.header : i.name}
                    </div>
                    <div className="item-bottom">
                      <div className="item-topic">
                        {tab !== "users" ? i.content : i.email}
                      </div>
                      <div className="item-author">
                        <Link
                          to={"/user/" + (tab !== "users" ? i.user_id : i.id)}
                        >
                          {tab !== "users" ? i.user_name : i.name}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="item-btn"
                >
                  <div>Жалоб: {i.complaint_count}</div>
                  <IconButton
                    onClick={() => {
                      i.delete().then(() => {
                        Reports.fetch();
                      });
                    }}
                  >
                    <HighlightOffIcon style={{ color: "black" }} />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default ReportsPage;
