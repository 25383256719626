import React from "react";
import { Link } from "react-router-dom";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { IconButton } from "@mui/material";

const Menu = () => {
  return (
  <ul className="menu">
    <li className="menu-item">
      <Link to="/">
      <IconButton style={{color:'black'}}>
        <QuestionAnswerIcon />
        Вопросы
      </IconButton>
      </Link>
    </li>
    <li className="menu-item">
      <Link to="/reports">
        <IconButton style={{color:'black'}}>
          <ReportProblemIcon />
          Жалобы
        </IconButton>
      </Link>
    </li>
    <li className="menu-item">
      <Link to="/users">
        <IconButton style={{color:'black'}}>
          <PeopleAltIcon />
          Пользователи
        </IconButton>
      </Link>
    </li>
    <li className="menu-item">
      <Link to="/topics">      
        <IconButton style={{color:'black'}}>
          <FormatListBulletedIcon />
          Категории
        </IconButton>
      </Link>
    </li>
  </ul>
  )
}

export default Menu;