import "./style/global.scss";
import Header from "./components/common/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Menu from "./components/common/Menu";
import Questions from "./components/Questions/Questions";
import ViewQuestionPage from "./components/Questions/ViewPage";
import EditQuestionPage from "./components/Questions/EditPage";
import Reports from "./components/Reports/Reports";
import Users from "./components/Users/Users";
import User from "./components/Users/User";
import Topics from "./components/Topics/Topics";
import Login from "./components/common/Login";
import useAuth from "./hooks/useAuth";
import { CircularProgress, Container, Grid } from "@mui/material";

function App() {
  const auth = useAuth();
  return (
    <Router>
      {auth.isLoaded ? (
        <>
          <Header />
          <div className="main">
            {auth.token ? <Menu /> : <></>}
              <Routes>
                <Route path="/" element={<Questions />} />
                <Route
                  path="/question/view/:id"
                  element={<ViewQuestionPage />}
                />
                <Route
                  path="/question/edit/:id"
                  element={<EditQuestionPage />}
                />
                <Route path="/reports" element={<Reports />} />
                <Route path="/users" element={<Users />} />
                <Route path="/user/:id" element={<User />} />
                <Route path="/topics" element={<Topics />} />
                <Route path="/login" element={<Login />} />
              </Routes>
          </div>
        </>
      ) : (
        <Container style={{ height: "100vh" }} spacing={3}>
          <Grid
            style={{ height: "100%" }}
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid style={{ paddingLeft: "0px", paddingTop: "0px" }} item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </Container>
      )}
    </Router>
  );
}

export default App;
