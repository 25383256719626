import { makeAutoObservable, onBecomeObserved } from "mobx";
import api from "../api/questions";
import { ReplyThread } from "./ReplyThread";
import { Topics } from './Topics';

class QuestionStore {
  id;
  content = "";
  user_id;
  user_name;
  user_email;
  correct_answer;
  rating;
  complains_count;
  question_id;
  created_on;
  header;
  topic_id;

  constructor({
    id,
    content,
    user_id,
    correct_answer,
    rating,
    created_on,
    complains_count,
    question_id,
    header,
    topic_id,
  }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.id = id;
    this.header = header;
    this.topic_id = topic_id;
    this.content = content;
    this.correct_answer = correct_answer;
    this.rating = rating;
    this.complains_count = complains_count;
    this.question_id = question_id;
    this.created_on = created_on;
    this.user_id = user_id.id;
    this.user_name = user_id.name;
    this.user_email = user_id.email;
  }
}

export class QuestionEditStore {
  id;
  content;
  topic_id;
  header;
  archived;
  user_id;
  created_on;
  complains_count;
  rating;
  correct_answer_id;
  reply_threads = [];
  newContent = "";
  newHeader = "";
  complaint_count;

  constructor({
    id,
    content,
    topic_id,
    header,
    archived,
    user_id,
    created_on,
    complains_count,
    rating,
    correct_answer_id,
    reply_threads,
    complaint_count,
  }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.id = id;
    this.content = content;
    this.topic_id = topic_id;
    this.header = header;
    this.archived = archived;
    this.user_id = user_id.id;
    this.user_name = user_id.name;
    this.user_email = user_id.email;
    this.created_on = created_on;
    this.complains_count = complains_count;
    this.rating = rating;
    this.correct_answer_id = correct_answer_id;
    this.reply_threads = reply_threads.map((i) => new ReplyThread(i));
    this.newContent = content;
    this.newHeader = header;
    this.complaint_count = complaint_count;
  }

  updateContent(content) {
    this.newContent = content;
  }

  updateHeader(header) {
    this.newHeader = header;
  }

  update(payload) {
    api.updateQuestion(payload.id, payload).then(() => {
      this.fetch();
    });
  }

  async close() {
    const resp = await api.close(this.id);
    return resp;
  }

  async delete() {
    const resp = await api.removeQuestion(this.id);
    return resp;
  }

  *fetch() {
    const response = yield api.getQuestion(this.id);

    this.id = response.id;
    this.content = response.content;
    this.topic_id = response.topic_id;
    this.header = response.header;
    this.archived = response.archived;
    this.user_id = response.user_id.id;
    this.user_name = response.user_id.name;
    this.user_email = response.user_id.email;
    this.created_on = response.created_on;
    this.complains_count = response.complains_count;
    this.rating = response.rating;
    this.correct_answer_id = response.correct_answer_id;
    this.reply_threads = response.reply_threads.map((i) => new ReplyThread(i));
    this.newContent = response.content;
    this.newHeader = response.header;
  }
}

class QuestionsStore {
  list = [];
  searchTopic = "";
  searchQuestion="";
  page = 0;
  totalPages;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
  }

  *add(payload) {
    yield api.addQuestion(payload);

    yield this.fetch();
  }

  *update(payload) {
    yield api.updateQuestion(payload.id, payload);

    yield this.fetch();
  }

  *delete(id) {
    yield api.removeQuestion(id);

    yield this.fetch();
  }

  *fetch() {
    const response = yield api.getQuestions({
      page: this.page,
      topic_id: this.searchTopic,
      find: this.searchQuestion,
    });

    this.totalPages = response.totalPages;

    this.list = response.content.map((i) => new QuestionStore(i));
  }

  search() {
    this.fetch();
  }

  updateSearchTopic(v) {
    this.searchTopic = v;
    this.fetch();
  }

  updateSearchQuestion(v) {
    this.searchQuestion = v;
  }

  setPage(p) {
    this.page = p;
    this.fetch();
  }
}

export const Questions = new QuestionsStore();
