import { observer } from "mobx-react-lite";
import { observable } from "mobx";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const Modal = observer(
  ({ onSubmit, onCancel, edit = false, children }) => {
    const [modal] = useState(() =>
      observable({
        open: false,
        openModal() {
          this.open = true;
        },
        closeModal() {
          this.open = false;
        },
      })
    );

    return (
      <>
        {edit ? (
          <IconButton onClick={() => modal.openModal()}>
            <EditIcon style={{ color: "black" }} />
          </IconButton>
        ) : (
          <Button
            onClick={() => modal.openModal()}
            variant="contained"
            style={{ background: "#002984" }}
          >
            Создать
          </Button>
        )}
        <Dialog
          open={modal.open}
          // onClose={() => modal.closeModal()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth="sm"
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            {edit ? "Редактирование категории" : "Создание категории"}
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions className="alert-btns">
            <Button
              variant="contained"
              style={{ background: "#002984" }}
              onClick={() => {
                onSubmit();
                modal.closeModal();
              }}
            >
              Сохранить
            </Button>
            <Button
              variant="outlined"
              style={{ color: "black", borderColor: "black" }}
              onClick={() => {
                onCancel();
                modal.closeModal();
              }}
              autoFocus
            >
              Отмена
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
