import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Topics } from "./../../store/Topics";
import api from "../../api/questions";
import { QuestionEditStore } from "./../../store/Questions";
import { Modal } from "../common/modal";
import { Dialog } from "../common/dialog";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CommentIcon from "@mui/icons-material/Comment";

const EditPage = observer((props) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [questionStore, setStore] = useState(null);

  useEffect(() => {
    api.getQuestion(id).then((data) => {
      console.log(data);
      setStore(new QuestionEditStore(data));
    });
  }, []);

  return (
    <div className="content">
      {questionStore && (
        <>
          <div className="page-header">
            <div className="title">Редактирование вопроса</div>
            <div className="page-btns">
              <Button
                onClick={() => {
                  questionStore.close().then((result) => {
                    if (result) {
                      navigate("/");
                    }
                  });
                }}
                variant="contained"
                style={{ background: "#002984" }}
              >
                Закрыть
              </Button>
              <Button
                onClick={() => {
                  questionStore.delete().then((result) => {
                    if (result) {
                      navigate("/");
                    }
                  });
                }}
                variant="contained"
                style={{ background: "darkred" }}
              >
                Удалить
              </Button>
            </div>
          </div>
          <div className={`question`}>
            <Box sx={{ p: 1 }}>
              <QuestionMarkIcon />
            </Box>
            <div className="question-content">
              <div className="question-info">
                <div>
                  <div>
                    {Topics.loaded &&
                      Topics.getById(questionStore.topic_id)?.content}
                    ,
                  </div>
                  <div>{questionStore.user_name}</div>
                </div>
                <div className="item-page-btn">
                  <Modal
                    edit={true}
                    onSubmit={() =>
                      questionStore
                        .update({
                          id: questionStore.id,
                          content: questionStore.newContent,
                          header: questionStore.newHeader,
                        })
                        .then((res) => {
                          questionStore.fetch();
                        })
                    }
                    onCancel={() => {
                      questionStore.updateHeader(questionStore.header);
                      questionStore.updateContent(questionStore.content);
                    }}
                  >
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1 },
                      }}
                    >
                      <div>
                        <TextField
                          autoFocus
                          fullWidth
                          label="Заголовок"
                          value={questionStore.newHeader}
                          onChange={(e) => {
                            questionStore.updateHeader(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          label="Текст"
                          value={questionStore.newContent}
                          onChange={(e) => {
                            questionStore.updateContent(e.target.value);
                          }}
                        />
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
              <div className="title">{questionStore.header}</div>
              <div className="question-description">
                {questionStore.content}
              </div>
            </div>
          </div>
          <div className="answers">
            {questionStore.reply_threads.map((reply_thread) => (
              <div key={reply_thread.id} className="answer">
                <div className={"answer-block"}>
                  <Box sx={{ p: 1 }}>
                    <QuestionAnswerIcon />
                  </Box>
                  <div className="comment-content">
                    <div>
                      <div className="comment-info">
                        <div>{reply_thread.user_name}</div>

                        <div className="item-page-btn">
                          <IconButton
                            onClick={() => {
                              reply_thread.define().then((res) => {
                                questionStore.fetch();
                              });
                            }}
                            color={
                              reply_thread.correct_answer
                                ? "primary"
                                : "default"
                            }
                          >
                            <ThumbUpIcon></ThumbUpIcon>
                          </IconButton>
                          <Modal
                            edit={true}
                            onSubmit={() =>
                              reply_thread
                                .update({
                                  id: reply_thread.id,
                                  content: reply_thread.newContent,
                                })
                                .then((res) => {
                                  questionStore.fetch();
                                })
                            }
                            onCancel={() => {
                              reply_thread.updateContent(reply_thread.content);
                            }}
                          >
                            <TextField
                              className="topics-edit"
                              autoFocus
                              id="name"
                              type="email"
                              variant="outlined"
                              value={reply_thread.newContent}
                              onChange={(e) => {
                                reply_thread.updateContent(e.target.value);
                              }}
                            />
                          </Modal>
                          <Dialog
                            title={"Удалить ответ?"}
                            onSubmit={() =>
                              reply_thread.delete().then((res) => {
                                questionStore.fetch();
                              })
                            }
                          ></Dialog>
                        </div>
                      </div>
                    </div>
                    <div className="comment-description">
                      {reply_thread.content}
                    </div>
                  </div>
                </div>
                <div className="comments">
                  {reply_thread.sub_replies.map((sub_reply) => (
                    <div key={sub_reply.id} className="comment">
                      <Box sx={{ p: 1 }}>
                        <CommentIcon />
                      </Box>
                      <div className="comment-content">
                        <div>
                          <div className="comment-info">
                            <div>{sub_reply.user_name}</div>
                            <div className="item-page-btn">
                              <Modal
                                edit={true}
                                onSubmit={() =>
                                  sub_reply
                                    .update({
                                      id: sub_reply.id,
                                      content: sub_reply.newContent,
                                    })
                                    .then((res) => {
                                      questionStore.fetch();
                                    })
                                }
                                onCancel={() => {
                                  sub_reply.updateContent(sub_reply.content);
                                }}
                              >
                                <TextField
                                  className="topics-edit"
                                  autoFocus
                                  id="name"
                                  type="email"
                                  variant="outlined"
                                  value={sub_reply.newContent}
                                  onChange={(e) => {
                                    sub_reply.updateContent(e.target.value);
                                  }}
                                />
                              </Modal>
                              <Dialog
                                title={"Удалить комментарий?"}
                                onSubmit={() =>
                                  sub_reply.delete().then((res) => {
                                    questionStore.fetch();
                                  })
                                }
                              ></Dialog>
                            </div>
                          </div>
                        </div>
                        <div className="comment-description">
                          {sub_reply.content}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
});

export default EditPage;
