import { makeAutoObservable, onBecomeObserved } from "mobx";
import api from "../api/questions";
import { SubReply } from './SubReply';

export class ReplyThread {
  id;
  content = "";
  created_on;
  question_id;
  complains_count;
  rating;
  correct_answer;
  user_id;
  user_name;
  user_email;
  sub_replies = [];
  newContent = "";
  complaint_count

  constructor({
    id,
    content,
    created_on,
    question_id,
    complains_count,
    rating,
    correct_answer,
    user_id,
    sub_replies,
    complaint_count
  }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.id = id;
    this.content = content;
    this.user_id = user_id.id;
    this.user_name = user_id.name;
    this.user_email = user_id.email;
    this.created_on = created_on;
    this.question_id = question_id;
    this.complains_count = complains_count;
    this.rating = rating;
    this.correct_answer = correct_answer;
    this.sub_replies = sub_replies.map((i) => new SubReply(i));
    this.newContent = content;
    this.complaint_count=complaint_count
  }

  updateContent(v) {
    this.newContent = v;
  }

  update(payload) {
    return api.updateReplyThread(payload.id, payload)
  }

  delete() {
    return api.deleteReplyThread(this.id, this.question_id)
  }

  define() {
    return api.defineReplyThread(this.id, this.question_id)
  }
}
