import React from "react";
import { observer } from "mobx-react-lite";
import { Topics } from "../../store/Topics";
import { Modal } from "../common/modal";
import { Dialog } from "../common/dialog";
import { TextField } from "@mui/material";
import { observable } from "mobx";
import { useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import { Box } from "@mui/system";

const TopicsView = observer(() => {
  const [addStore] = useState(() =>
    observable({
      id: Math.random(),
      content: "",

      updateContent(v) {
        this.content = v;
      },
      clear() {
        this.content = "";
        this.id = Math.random();
      },
    })
  );

  return (
    <div className="content">
      <div className="reports">
        <div className="topics-header">
          <div className="title">Категории</div>
          <Modal
            edit={false}
            onSubmit={() => {
              Topics.add({
                id: addStore.id,
                content: addStore.content,
                description: "",
              });
              addStore.clear();
            }}
            onCancel={() => addStore.clear()}
          >
            <TextField
              className="topics-edit"
              autoFocus
              id="name"
              type="email"
              variant="outlined"
              onChange={(e) => {
                addStore.updateContent(e.target.value);
              }}
              value={addStore.content}
            />
          </Modal>
        </div>
        <div className="list">
          {Topics.list.map(
            ({ id, content, description, updateContent, newContent }) => {
              return (
                <div key={id} className="item">
                  <div className="item-content">
                    <Box sx={{ p: 1 }}>
                      <CategoryIcon />
                    </Box>
                    <div className="item-info">
                      <div className="item-title">{content}</div>
                    </div>
                  </div>
                  <div className="item-btn">
                    <Modal
                      edit={true}
                      onSubmit={() =>
                        Topics.update({ id, content: newContent, description })
                      }
                      onCancel={() => {
                        updateContent(content);
                      }}
                    >
                      <TextField
                        className="topics-edit"
                        autoFocus
                        id="name"
                        type="email"
                        variant="outlined"
                        value={newContent}
                        onChange={(e) => {
                          updateContent(e.target.value);
                        }}
                      />
                    </Modal>
                    <Dialog
                      title={"Удалить категорию?"}
                      onSubmit={() => {
                        Topics.delete(id);
                      }}
                    ></Dialog>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
});

export default TopicsView;
