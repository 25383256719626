import req from "../../services/api/request";

class TopicsApi {
  getTopics(params) {
    return req.get("/api/valid/topics");
  }
  addTopic(params) {
    return req.post(`/api/valid/topic?topic_name=${params.content}`, {});
  }
  removeTopic(id) {
    return req.delete(`/api/admin/topic/${id}`);
  }

  updateTopic(id, {content}) {
    return req.post(`/api/valid/topic/${id}?topic_name=${content}`, {});
  }
}

export default new TopicsApi();
