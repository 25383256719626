import { observer } from "mobx-react-lite";
import { observable } from "mobx";
import { useState } from "react";
import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import Window from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

export const Dialog = observer(({ onSubmit, title = "", submitText = "Удалить", buttonType="error" }) => {
  const [modal] = useState(() =>
    observable({
      open: false,
      openModal() {
        this.open = true;
      },
      closeModal() {
        this.open = false;
      }
    })
  );

  const handleClose = () => {
    onSubmit()
    modal.closeModal();
  };

  return (
    <>
      <IconButton onClick={() => modal.openModal()}>
        <HighlightOffIcon style={{ color: "black" }} />
      </IconButton>
      <Window
        open={modal.open}
        onClose={()=>modal.closeModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogActions className="alert-btns">
          <Button
            variant="contained"
            style={{ background: "darkred" }}
            onClick={handleClose}
            color={buttonType}
          >
            {submitText}
          </Button>
          <Button
            variant="outlined"
            style={{ color: "black", borderColor: "black" }}
            onClick={()=>modal.closeModal()}
            autoFocus
          >
            Отмена
          </Button>
        </DialogActions>
      </Window>
    </>
  );
});
