import {
  flow,
  makeObservable,
  makeAutoObservable,
  observable,
  onBecomeObserved,
} from "mobx";
import api from "../api/topics";

class TopicStore {
  id;
  content = "";
  description = "";
  newContent = "";

  constructor({ id, content, description }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.id = id;
    this.content = content;
    this.description = description;
    this.newContent=content
  }

  updateContent(content) {
    this.newContent = content;
  }

  updateDescription(description) {
    this.description = description;
  }
}

class TopicsStore {
  list = [];
  loaded = false;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );
    this.fetch()
  }

  add(payload) {
    api.addTopic(payload).then(()=>{
      console.log('fetch')
      this.fetch();
    });
  }

  *update(payload) {
    yield api.updateTopic(payload.id, payload);

    yield this.fetch();
  }

  *delete(id) {
    yield api.removeTopic(id);

    yield this.fetch();
  }

  *fetch() {
    this.loaded = false;
    const response = yield api.getTopics();
    
    this.list = response.map((i) => new TopicStore(i));
    this.loaded=true
  }

  getById(id){
    return this.list.find((p) => p.id === id)
  }
}

export const Topics = new TopicsStore();
