import { makeAutoObservable, onBecomeObserved } from "mobx";
import api from "../api/users";

class UserStore {
  id;
  name;
  email;
  block;

  constructor({ id, name, email, blocked }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.id = id;
    this.name = name;
    this.email = email;
    if(blocked===null) {
this.block=false
    }else{
    this.block = blocked;}
  }

  updateName(name) {
    this.name = name;
  }

  updateDescription(email) {
    this.email = email;
  }
}

class UsersStore {
  list = [];
  searchUser="";
  showBlocked = false;
  page = 0;
  totalPages

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    onBecomeObserved(this, "list", this.fetch);
  }

  *add(payload) {
    yield api.addUser(payload);

    yield this.fetch();
  }

  *update(payload) {
    yield api.updateUser(payload.id, payload);

    yield this.fetch();
  }

  *delete(id) {
    yield api.removeUser(id);

    yield this.fetch();
  }

  *blockUser(id) {
    yield api.blockUser(id);

    yield this.fetch();
  }

  *unblockUser(id) {
    yield api.unblockUser(id);

    yield this.fetch();
  }

  *fetch() {
    const response = yield api.getUsers({
      fio: this.searchUser,
      page: this.page,
    });

    this.totalPages=response.totalPages

    this.list = response.content.map((i) => new UserStore(i));
  }

  search() {
    this.fetch();
  }

  updateSearchUser(v) {
    this.searchUser = v;
  }

  setShowBlocked(v) {
    this.showBlocked = v;
  }

  setpage(page) {
    this.page = page;
    this.fetch();
  }
}

export const Users = new UsersStore();
