import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CheckIcon from "@mui/icons-material/Check";
import { Topics } from "../../store/Topics";
import api from "../../api/users";
import { UserStore } from "../../store/User";
import { Modal } from "../common/modal";
import { Dialog } from "../common/dialog";
import { TextField, Tabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PersonIcon from "@mui/icons-material/Person";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CommentIcon from "@mui/icons-material/Comment";

const UserPage = observer((props) => {
  let { id } = useParams();

  const [tab, setTab] = useState("questions");

  const [userStore, setStore] = useState(null);

  useEffect(() => {
    api.getUser(id).then((data) => {
      console.log(data);
      setStore(new UserStore(data));
    });
  }, []);

  return (
    <div className="content">
      {userStore && (
        <>
          <div className="page-header">
            <div className={"answer-block"}>
              <Box sx={{ p: 2 }}>
                <PersonIcon />
              </Box>
              <div className="comment-content">
                <div className="comment-info">
                  <div>{userStore.name}</div>
                </div>
                <div className="comment-info">
                  <div>{userStore.email}</div>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            value={tab}
            onChange={(e, newValue) => {
              setTab(newValue);
            }}
          >
            <Tab value="questions" label="Вопросы" />
            <Tab value="reply_threads" label="Ответы" />
            <Tab value="sub_replies" label="Комментарии" />
          </Tabs>
          <div>
            <div className="list">
              {userStore[tab].map((item) => (
                <div key={item.id} className="item">
                  <div className="item-content">
                    <Box sx={{ p: 1 }}>
                      {tab === "questions" && <QuestionMarkIcon />}
                      {tab === "reply_threads" && <QuestionAnswerIcon />}
                      {tab === "sub_replies" && <CommentIcon />}
                    </Box>
                    <div className="item-info">
                      {item.header && (
                        <div className="item-title">{item.header}</div>
                      )}
                      <div className="item-title">{item.content}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default UserPage;
