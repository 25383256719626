import { makeAutoObservable, onBecomeObserved } from "mobx";
import api from "../api/users";

export class UserStore {
  id;
  name;
  email;
  sub_replies;
  reply_threads;
  questions;
  complaint_count;

  constructor({
    id,
    name,
    email,
    sub_replies,
    reply_threads,
    questions,
    complaint_count,
  }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.id = id;
    this.name = name;
    this.email = email;
    this.sub_replies = sub_replies;
    this.reply_threads = reply_threads;
    this.questions = questions;
    this.complaint_count = complaint_count;
  }

  delete() {
    return api.removeUser(this.id);
  }
}
