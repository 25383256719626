import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import useAuth from "./../../hooks/useAuth/index";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

const Login = () => {
  const auth = useAuth();
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setemail] = useState();
  const [password, setpassword] = useState();

  const onSubmit = async (data) => {
    setIsLoading(true);

    const requestData = {
      username: email,
      password: password,
    };

    api.auth
      .login(requestData)
      .then((response) => {
        if (response.id) {
          auth.setUser({
            email: response.email.trim(),
            id: response.id,
          });
          auth.setToken(response.jwt);
          navigate("/");
          setError(false);
          localStorage.setItem("token", response.jwt);
          localStorage.setItem("userId", response.id);
        }
        if (response.status === 401) {
          setError(true);
        }
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return <div className="login">
    <div className="title">Авторизация</div>
    <TextField
      label="Логин"
      autoFocus
      id="email"
      type="email"
      variant="outlined"
      value={email}
      error={error}
      onChange={(e)=>{setemail(e.target.value)}}
    />
    <TextField
      label="Пароль"
      id="password"
      type="password"
      value={password}
      variant="outlined"
      error={error}
      onChange={(e)=>{setpassword(e.target.value)}}
    />
    <Button disabled={isLoading} onClick={onSubmit} style={{color: '#002984'}}>ВОЙТИ</Button>
  </div>
}

export default Login;