import req from "../../services/api/request";

class UsersApi{
  getUsers(params) {
    return req.get(`/api/admin/users?fio=${params.fio}&count=10&page=${params.page}`);
  }
  getUser(id) {
    return req.get(`/api/valid/user/${id}`);
  }

  /*addUser(params) {
    return this.post("/users", params);
  }*/

  removeUser(id) {
    return req.delete(`/api/admin/user/${id}`);
  }

  blockUser(id) {
    return req.get(`/api/admin/block/${id}`);
  }

  unblockUser(id) {
    return req.get(`/api/admin/unblock/${id}`);
  }

  // TODO
  updateUser(id, params) {
    return req.post("/users", id, params);
  }
}

export default new UsersApi();
