import { makeAutoObservable, onBecomeObserved } from "mobx";
import api from "../api/reports";
import { ReplyThread } from './ReplyThread';
import { QuestionEditStore } from './Questions';
import { SubReply } from './SubReply';
import { UserStore } from './User';

class ReportsStore {
  list = {
    questions:[],
    answers:[],
    comments:[],
    users:[],
  };
  searchTopic = null;
  searchStr;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    onBecomeObserved(this, "list", this.fetch);
  }

  *fetch() {
    const response = yield api.getReports();

    this.list.questions = response.questions.map((i) => new QuestionEditStore(i));
    this.list.answers = response.answers.map((i) => new ReplyThread(i));
    this.list.comments = response.comments.map((i) => new SubReply(i));
    this.list.users = response.users.map((i) => new UserStore(i));
  }

  updateSearchTopic(v) {
    this.searchTopic = v;
  }

  updateSearchStr(v) {
    this.searchStr = v;
  }
}

export const Reports = new ReportsStore();
