import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";

function AuthProvider(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setTokenData] = useState(null);

  const setToken = useCallback((tokenData) => {
    setTokenData(tokenData);

    if (tokenData) {
      localStorage.setItem("token", tokenData);
    } else {
      localStorage.removeItem("token");
    }
  }, []);

  const logOut = useCallback(() => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    window.location='/login'
  }, [setToken]);

  const loadData = useCallback(async () => {
    const tokenData = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!tokenData || !userId) {
      setToken(null);
      setUser(null);
    } else {
      setTokenData(tokenData);
      setUser({ id: userId });
    }

    setIsLoaded(true);
  }, [setToken]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const contextValue = useMemo(
    () => ({
      isLoaded,
      user,
      token,
      setUser,
      setToken,
      logOut,
    }),
    [isLoaded, user, token, setToken, logOut]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
