import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useAuth from "./../../hooks/useAuth/index";
import { Topics } from "./../../store/Topics";
import { Button } from "@mui/material";

const Header = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.token) {
      Topics.fetch();
    }
  }, [auth]);

  return (
    <div className="header">
      <Link to="/" className="header-title">
        ФОРУМ | Панель администратора
      </Link>
      <div className="header-menu">
        <a href="https://map.ncpti.ru/admin">Карты</a>
        {auth.token ? (
          <Button
            onClick={() => {
              auth.logOut();
            }}
            className="logout"
          >
            Выход
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Header;
