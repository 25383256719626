import { makeAutoObservable, onBecomeObserved } from "mobx";
import api from "../api/questions";

export class SubReply {
  id;
  created_on;
  content;
  complaint_count;
  user_id;
  thread_id;
  newContent = "";

  constructor({
    id,
    created_on,
    content,
    complaint_count,
    user_id,
    thread_id,
  }) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    this.id = id;
    this.content = content;
    this.user_id = user_id.id;
    this.user_name = user_id.name;
    this.user_email = user_id.email;
    this.created_on = created_on;
    this.complaint_count = complaint_count;
    this.thread_id = thread_id;
    this.newContent = content;
  }

  updateContent(v) {
    this.newContent = v;
  }

  update(payload) {
    return api.updateSubReply(payload.id, payload);
  }

  delete() {
    return api.deleteSubReply(this.id, this.thread_id);
  }
}