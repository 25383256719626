import React from "react";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { Button, IconButton, Pagination } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Users } from "../../store/Users";
import { observer } from "mobx-react-lite";
import { Dialog } from "./../common/dialog/index";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from "@mui/material/Box";

const UsersView = observer(() => {
  return (
    <div className="content">
      <div className="reports">
        <div className="search">
          <div className="title search-users">
            <span
              onClick={() => {
                Users.setShowBlocked(false);
              }}
              className={!Users.showBlocked ? "active" : ""}
            >
              Пользователи
            </span>
            <span
              onClick={() => {
                Users.setShowBlocked(true);
              }}
              className={Users.showBlocked ? "active" : ""}
            >
              Заблокированые
            </span>
          </div>
          <div className="search-panel">
            <div className="search-field">
              <IconButton className="search-icon" onClick={() => {}}>
                <SearchSharpIcon />
              </IconButton>
              <InputBase
                id="outlined-basic"
                placeholder="Поиск..."
                value={Users.searchUser}
                onChange={(e) => {
                  Users.updateSearchUser(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    Users.search();
                  }
                }}
              />
              <Button
                onClick={(e) => {
                  Users.search();
                }}
                variant="contained"
                style={{ background: "#002984", borderRadius: "0 5px 5px 0" }}
              >
                Найти
              </Button>
            </div>
          </div>
        </div>
        <div className="list">
          {Users.list
            .filter((i) => i.block === Users.showBlocked)
            .map(({ name, email, id }) => {
              return (
                <div key={id} className="item">
                  <div className="item-content">
                    <Box sx={{ p: 1 }}>
                      <AccountCircleIcon />
                    </Box>
                    <div className="item-info">
                      <div className="item-title">{name}</div>
                      <div className="item-bottom">
                        <div className="item-email">{email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="item-btn">
                    <Link to={`/user/${id}`}>
                      <IconButton>
                        <VisibilityIcon style={{ color: "black" }} />
                      </IconButton>
                    </Link>
                    <Dialog
                      buttonType="success"
                      submitText={
                        Users.showBlocked ? "Разблокировать" : "Заблокировать"
                      }
                      title={
                        Users.showBlocked
                          ? "Разблокировать пользователя?"
                          : "Заблокировать пользователя?"
                      }
                      onSubmit={() =>
                        Users.showBlocked
                          ? Users.unblockUser(id)
                          : Users.blockUser(id)
                      }
                    ></Dialog>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <Pagination
            page={Users.page + 1}
            count={Users.totalPages}
            onChange={(e, newPage) => {
              Users.setpage(newPage);
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default UsersView;
