import req from "../../services/api/request";

class ReportsApi {
  getReports(params) {
    return req.get("/api/admin/complaints/");
  }
  removeQuestion(id){
    return req.delete(`/api/valid/question/${id}`)
  }
  removeComment(id){
    return req.delete(`/api/valid/comment/${id}`)
  }
  removeAnswer(id){
    return req.delete(`/api/valid/answer/${id}`)
  }
  removeUser(id){
    return req.delete(`/api/admin/user/${id}`)
  }
}

export default new ReportsApi();
