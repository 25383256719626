import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Topics } from "./../../store/Topics";
import api from "../../api/questions";
import { QuestionEditStore } from "./../../store/Questions";
import Box from "@mui/material/Box";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CommentIcon from "@mui/icons-material/Comment";

const ViewPage = observer(() => {
  let { id } = useParams();

  const [questionStore, setStore] = useState(null);

  useEffect(() => {
    api.getQuestion(id).then((data) => {
      console.log(data);
      setStore(new QuestionEditStore(data));
    });
  }, []);

  return (
    <div className="content">
      <div>
        <div className="page-header">
          <div className="title">Просмотр вопроса</div>
        </div>
        {questionStore && (
          <>
            <div className="question">
              <Box sx={{ p: 1 }}>
                <QuestionMarkIcon />
              </Box>
              <div className="question-content">
                <div className="question-info">
                  <div>
                    <div>
                      {Topics.loaded &&
                        Topics.getById(questionStore.topic_id)?.content}
                      ,
                    </div>
                    <div>{questionStore.user_name}</div>
                  </div>
                </div>
                <div className="title">{questionStore.header}</div>
                <div className="question-description">
                  {questionStore.content}
                </div>
              </div>
            </div>
            <div className="answers">
              {questionStore.reply_threads.map((reply_thread) => (
                <div key={reply_thread.id} className="answer">
                  <div className="answer-block">
                    <Box sx={{ p: 1 }}>
                      <QuestionAnswerIcon />
                    </Box>
                    <div className="answer-block-content">
                      <div className="answer-block-info">
                        <div>{reply_thread.user_name}</div>
                      </div>
                      <div className="answer-block-description">
                        {reply_thread.content}
                      </div>
                    </div>
                  </div>
                  <div className="comments">
                    {reply_thread.sub_replies.map((sub_reply) => (
                      <div key={sub_reply.id} className="comment">
                        <Box sx={{ p: 1 }}>
                          <CommentIcon />
                        </Box>
                        <div className="comment-content">
                          <div>
                            <div className="comment-info">
                              <div>{sub_reply.user_name}</div>
                            </div>
                          </div>
                          <div className="comment-description">
                            {sub_reply.content}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default ViewPage;
